<template>
  <RevDialog
    :closeButtonLabel="i18n(translations.close)"
    :name="MODAL_NAMES.BATTERY_REPLACEMENT"
    :title="i18n(translations.title)"
  >
    <template #body>
      <div class="space-y-16">
        <div class="relative">
          <div class="flex justify-center">
            <RevIllustration
              :alt="i18n(translations.illustrationAlternative)"
              class="z-10"
              :height="168"
              src="/img/battery-replacement/battery-illustration.svg"
              :width="148"
            />
          </div>
          <div
            class="rounded-lg absolute top-[22px] flex h-[123px] w-full bg-[#E2F77E]"
          />
        </div>

        <div>
          <div class="heading-3 flex justify-between">
            <div>{{ i18n(translations.subTitle) }}</div>
            <div>{{ i18n.price(insuranceOffer.price) }}</div>
          </div>
          <div class="body-2">{{ deviceName }}</div>
        </div>

        <div class="space-y-8">
          <div
            v-for="benefit in insuranceOffer.benefits.long"
            :key="benefit"
            class="flex items-center gap-4"
          >
            <IconCheckInCircle class="text-static-success-hi h-16 w-16" />
            <div class="text-static-default-low body-2">{{ benefit }}</div>
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="space-y-12">
        <RevButton
          fullWidth="always"
          variant="primary"
          @click="$emit('accept')"
        >
          {{ i18n(translations.accept) }}
        </RevButton>
        <RevButton
          fullWidth="always"
          variant="secondary"
          @click="$emit('ignore')"
        >
          {{ i18n(translations.ignore) }}
        </RevButton>
      </div>
    </template>
  </RevDialog>
</template>

<script setup lang="ts">
import { type CheckoutInsuranceOffer } from '@backmarket/http-api/src/api-specs-checkout/cart/cart.types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevDialog } from '@ds/components/Dialog'
import { RevIllustration } from '@ds/components/Illustration'
import { IconCheckInCircle } from '@ds/icons/IconCheckInCircle'

import { MODAL_NAMES } from '~/scopes/product/constants'

import translations from './BatteryReplacementModal.translations'

defineProps<{
  insuranceOffer: CheckoutInsuranceOffer
  deviceName: string
}>()

defineEmits(['accept', 'ignore'])

const i18n = useI18n()
</script>
